$(document).ready(function() {

    /* mobile navigation */


    $('.menutogglecontainer').click(function() {
      $('#nav-main').toggleClass('open');
      $('html').toggleClass('noscroll');
      $('body').toggleClass('noscroll');
    });


    /* copy submenu to bottom */
      // Check if there is a <ul> inside '#nav-main > ul > li.current-menu-item'
      if ($('#nav-main > ul > li.current-menu-item ul').length > 0) {
        // Copy the whole 'li.current-menu-item' element into '#nav-submenu'
        $('#nav-main > ul > li.current-menu-item > ul').clone().appendTo('#nav-submenu');
      } else if ($('#nav-main > ul > li.current-menu-parent ul').length > 0) {
        $('#nav-main > ul > li.current-menu-parent > ul').clone().appendTo('#nav-submenu');
      }

      /* copy submenu to bottom */

    /* desktop navigation */
    var timer;
    /*
    $('#nav-main > ul > li').hover(function() {
      // Clears timeout if another li is hovered over, closing any other open dropdowns
      clearTimeout(timer);
      $('#nav-main > ul > li > ul').hide();
      $(this).find('> ul').show();
    }, function() {
      // Start a timer to delay the closing of the dropdown
      var dropdown = $(this).find('> ul');
      timer = setTimeout(function() {
        dropdown.hide();
      }, 500); // Delay the closing by 500 milliseconds
    });
    */
    /* /desktop navigation */


/* place sub nav */

    var subNav = $('#nav-submenu');
    if(subNav) {
      var subNavTop = subNav.height();
      subNav.css('top', window.innerHeight - subNavTop + 'px');
    }

  });
  

  document.addEventListener('DOMContentLoaded', function () {

    /* slider */
    var mySwiper = new Swiper('.swiper-container', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        autoplay: {
          delay: 8000,
        },
        // If you want pagination
        pagination: {
            el: '.swiper-pagination',
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        // And if you need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar',
        },
    });
    /* /slider */
});

/* sticky nav */
$(window).scroll(function() {
  if ($(this).scrollTop() > ($('#header').height() + 150)) {
    $('#header').addClass('sticky');
  } else {
    $('#header').removeClass('sticky');
  }

  var subNav = $('#nav-submenu');
  if (subNav.length) {
    if ($(this).scrollTop() > ($(window).height() - subNav.height())) {
      subNav.addClass('sticky');
    } else {
      subNav.removeClass('sticky');
    }
  }
});

  /* /sticky nav */